import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Dropdown, Form } from "react-bootstrap";
import question from "../../assets/images/question.svg";
import { useDispatch, useSelector } from "react-redux";
import { Country, State, City } from "country-state-city";
import { userCreateStore, getSiteSetting } from "../../action/user.action";
import { getUserStore } from "../../redux/features/userstore/userStoreSlice";
import { toastAlert } from "../../utils/toast";
import { getUserProfile } from "../../redux/features/user/userslice";
import config from "../../config/index";

function AddStorePop(props) {
  const dispatch = useDispatch();

  const [activecat, setactivecat] = useState(0);
  const [selectedValue, setSelectedValue] = useState(null);
  const [storeName, setstoreName] = useState("");
  const [validateError, setValidateError] = useState({});
  const [setting, setsetting] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const countries = Country.getAllCountries();
  const [file, setfile] = useState("")
  const [preview, setpreview] = useState("")

  let { show1, setShow1 } = props;
  const defaultStore = useSelector((state) => state.defaultstore);

  const handleClose1 = () => {
    setShow1(false)
    setpreview("")
    setstoreName("")
    setSelectedValue(null)
  }


  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSelect = (selectedItem) => {
    setSelectedValue(JSON.parse(selectedItem));
  };

  const handleStore = (item) => {
    setactivecat(item?._id);
  };

  const filteredCountries = countries.filter((country) =>
    country.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const Fromvalidation = async () => {
    try {
      var validateError = {};

      if (storeName.trim() == "") {
        validateError.name = "Store Name Required";
      }
      if (selectedValue == null) {
        validateError.country = "Country Required";
      }

      if (activecat == 0) {
        validateError.activecat = "Please Select Default Store";
      }

      setValidateError(validateError);
      return validateError;
    } catch (err) {
      //console.log(err);
    }
  };

  const CreatStore = async () => {
    try {
      const check = await Fromvalidation();
      var errorsSize = Object.keys(check).length;
      if (errorsSize == 0) {


        let Formdata = new FormData();
        Formdata.append("storeid", activecat);
        Formdata.append("storename", storeName);
        Formdata.append("location", selectedValue.name);
        Formdata.append("code", selectedValue.isoCode);
        Formdata.append("image", file);



        const { message, status } = await userCreateStore(Formdata);
        if (status) {
          dispatch(getUserStore());
          dispatch(getUserProfile());

          toastAlert("success", message);
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          toastAlert("error", message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getSitting();
  }, []);

  const getSitting = async () => {
    try {
      var { status, result } = await getSiteSetting();
      if (status) {
        setsetting(result);
      }
    } catch (err) {
      console.log(err);
    }
  };


  const handlefilechanges = ({ target: { files } }) => {
    if (!files.length) {
      return;
    }

    let picture = files[0];
    if (!picture.type.toLowerCase().startsWith("image/")) {
      throw Error("Invalid Image Format");
    }
    const fileSize = file.size / 1024 / 1024;
    if (fileSize > 30) {
      throw Error("File size exceeds 30 MB");
    }

    let pre = URL.createObjectURL(picture)
    setpreview(pre)
    setfile(picture)

  };

  return (
    <>
      <Modal
        show={show1}
        centered
        className="custom_modal"
        size="lg"
        onHide={handleClose1}
      >
        <Modal.Header className="mb-3" closeButton>
          <Modal.Title>Add Store</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label className="sem sm_txt mb-3">Store Location</label>
          <div className="drp_dwn drp_dwn_hgt mb-4">
            <Dropdown onSelect={handleSelect}>
              <Dropdown.Toggle className="d-flex justify-content-between">
                {selectedValue ? ` ${selectedValue?.name}` : "Select Country"}
              </Dropdown.Toggle>

              <Dropdown.Menu className="w-100">
                <input
                  type="text"
                  style={{ width: "90%" }}
                  className="form-control mb-2 mx-3"
                  placeholder="Search for a country..."
                  value={searchQuery}
                  onChange={handleInputChange}
                />
                {
                  filteredCountries.map((item) => (
                    <Dropdown.Item eventKey={JSON.stringify(item)}>
                      {item?.name}
                    </Dropdown.Item>
                  ))
                  // <Dropdown.Item eventKey="United States">United States</Dropdown.Item>
                }
              </Dropdown.Menu>
            </Dropdown>
            {validateError.country && (
              <span className="text-danger">{validateError.country}</span>
            )}
          </div>

          <div>
            <label className="sem sm_txt mb-3">Enter Store Name</label>
            <div className="drp_dwn mb-4">
              <input
                type="text"
                className="form-control"
                name="text"
                placeholder="Enter Store Name"
                onChange={(e) => {
                  setstoreName(e.target.value);
                }}
              ></input>
              {validateError.name && (
                <span className="text-danger">{validateError.name}</span>
              )}
            </div>
          </div>

          <div>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label className="bg-transparent">
                <span>
                  Store Logo
                </span>
              </Form.Label>
              <Form.Control type="file"

                onChange={handlefilechanges} accept=".png,.jpeg,.jpg" />
            </Form.Group>
            <p style={{ color: "#e9618c" }}>Acceptable size: 30MB format: png,jpeg,jpg</p> 
          
          </div>

          <div className="round">
            {preview != "" ?
              <>
                <img
                  style={{ width: "40px" }}
                  src={preview}
                  class="img-fluid" alt="img" />
              </>
              :
              ""
            }
          </div>


          <div className="d-flex align-items-center gap-1 justify-content-end">
            <span className="sm_txt" style={{fontSize:'15px'}}>${setting?.StoreAmount}/Month</span>
            <img src={question} className="img-fluid" />
          </div>
          <div className="sem sm_txt mb-3">Select Store Type</div>
          <div className="store_box">
            {defaultStore.length > 0 &&
              defaultStore?.map((item, index) => {
                return (
                  <div
                    onClick={() => handleStore(item)}
                    className={activecat == item?._id ? "active" : ""}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={`${config.API_URL}/store/${item?.logo}`}
                      class="img-fluid"
                      alt="img"
                    />
                  </div>
                );
              })}
          </div>
          {validateError.activecat && (
            <span className="text-danger">{validateError.activecat}</span>
          )}
          <div className="add mt-3">
            <button className="primary_btn" onClick={CreatStore}>
              {" "}
              Create Store
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddStorePop;
