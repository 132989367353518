import React, { useEffect, useState, useRef } from "react";
// img
import hide from "../assets/images/hide.svg";
import login from "../assets/images/login.svg";
import lock from "../assets/images/lock.svg";
import email from "../assets/images/email.svg";
import eye from "../assets/images/eye.svg";
import user from "../assets/images/user.svg";
import mobile from "../assets/images/mobile.svg";
import register_logo from "../assets/images/logo_dark.png";
import { ValidateUserName, validSpecialPositive, ValidateEmail } from "../helper/validation";
import { registerUser, isValidCampign } from "../action/user.action";
import { toastAlert } from "../utils/toast";
import { validPositive } from "../helper/validation";
// import { useParams } from "react-router-dom";

let initialstate = {
  Username: "",
  lastname: "",
  emailId: "",
  phone: "",
  password: "",
  confirmpassword: ""
};

export default function Register() {
  // const { CampaignId } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [reshowPassword, setreShowPassword] = useState(false);

  const [formdata, setformdata] = useState(initialstate);
  const [validateError, setValidateError] = useState({});
  const [passmessage, setpassmessage] = useState("");

  const [message, setMessage] = useState("");
  const [progress, setProgress] = useState("");
  const [disable, setDisable] = useState(false)
  // const [isValidC, setIsValidC] = useState(false);

  const { Username, lastname, emailId, phone, password, confirmpassword } = formdata;

  // useEffect(() => {
  //   checkValidCapmCode();
  // }, [CampaignId]);

  // const checkValidCapmCode = async () => {
  //   if (CampaignId) {
  //     console.log("Hi");
  //     const payload = {
  //       refCode: CampaignId
  //     };
  //     const check = await isValidCampign(payload);
  //     console.log(check,'check');
  //     if (check.result) {
  //       setIsValidC(true);
  //     }
  //   }
  // };

  const handleChange = (e) => {
    try {
      e.preventDefault();
      const { name, value } = e.target;
      let formData = { ...formdata, ...{ [name]: value } };
      setformdata(formData);
      if (name == "password") {
        handlePassword1(value);
      }
    } catch (err) { }
  };

  const Fromvalidation = async () => {
    try {
      var validateError = {};
      if (Username.trim() == "") {
        validateError.name = "First Name Required";
      } else if (!ValidateUserName(Username)) {
        validateError.name = "Alphabets Only Allowed";
      }

      if (lastname.trim() == "") {
        validateError.lastname = "Last Name Required";
      } else if (!ValidateUserName(lastname)) {
        validateError.lastname = "Alphabets Only Allowed";
      }


      if (emailId.trim() == "") {
        validateError.email = "Email Required";
      } else if (!ValidateEmail(emailId)) {
        validateError.email = "Invalid Email Address";
      }
      if (phone.trim() == "") {
        validateError.phoneNo = "Mobile Number Required";
      }

      if (password.trim() == "") {
        validateError.password = "Password Required";
      } else if (Object.keys(passmessage).length != 0) {
        validateError.password = passmessage;
      }
      if (confirmpassword.trim() == "") {
        validateError.confirmpassword = "Confirm Password Required";
      } else if (password != confirmpassword) {
        validateError.confirmpassword =
          "Password and Confirm Password Does not Match";
      }

      setValidateError(validateError);
      return validateError;
    } catch (err) {
      //console.log(err);
    }
  };

  const handleSubmit = async () => {
    try {
      const check = await Fromvalidation();
      var errorsSize = Object.keys(check).length;
      if (errorsSize == 0) {
        var reqbody = {
          email: emailId,
          password: password,
          phone: phone,
          confirmPassword: confirmpassword,
          name: Username,
          lastname: lastname
          // CampaignId:CampaignId
        };
        setDisable(true)
        const data = await registerUser(reqbody);

        if (data.status == false) {
          // setloading(false);
          var validateError = {};
          validateError.email = data.error.email;
          validateError.password = data.error.password;
          validateError.phoneNo = data.error.phoneNo;
          setValidateError(validateError);
        } else {
          toastAlert("success", "Register Successfully");
          sessionStorage.setItem("USER_AUTH_VERIFY_TOKEN_DATA", data.result);
          setDisable(false)
          setTimeout(() => {
            window.location.href = "/verify-mail";
          }, [1500]);
        }
      }
    } catch (err) {
      console.log(err);
      setDisable(false)
    }
  };

  const handlePassword1 = (passwordValue) => {
    const strengthChecks = {
      length: 0,
      length1: 0,
      hasUpperCase: false,
      hasLowerCase: false,
      hasDigit: false,
      hasSpecialChar: false
    };

    strengthChecks.length = passwordValue.length >= 8 ? true : false;
    strengthChecks.length1 = passwordValue.length < 18 ? true : false;
    strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
    strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
    strengthChecks.hasDigit = /[0-9]+/.test(passwordValue);
    strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);
    var errormsg = {};
    if (!strengthChecks.length) {
      errormsg = "Password must contain at least 8 characters";
    } else if (!strengthChecks.length1) {
      errormsg = "Password must contain less then 18 characters";
    } else if (!strengthChecks.hasUpperCase) {
      errormsg = "Password must contain 1 uppercase letter";
    } else if (!strengthChecks.hasLowerCase) {
      errormsg = "Password must contain 1 lowercase letter";
    } else if (!strengthChecks.hasDigit) {
      errormsg = "Password must contain 1 numeric character";
    } else if (!strengthChecks.hasSpecialChar) {
      errormsg = "Password must contain 1 special character";
    }
    setpassmessage(errormsg);
    let verifiedList = Object.values(strengthChecks).filter((value) => value);
    let strength =
      verifiedList.length == 6
        ? "Strong"
        : verifiedList.length >= 2 && strengthChecks.length1 == true
          ? "Medium"
          : "Weak";

    setProgress(`${(verifiedList.length / 6) * 100}%`);
    setMessage(strength);
  };

  const getActiveColor = (type) => {
    if (type === "Strong") return "#8BC926";
    if (type === "Medium") return "#FEBD01";
    return "red";
  };

  const handlePassword = () => {
    setShowPassword(!showPassword);
  };

  const rePassword = () => {
    setreShowPassword(!reshowPassword);
  };

  const containedChange = (event) => {
    if (event.key.length === 1 && event.code.startsWith('Key') && event.code.length === 4 && event.target.value.indexOf(event.key) !== -1) {
      event.preventDefault()
      return false;
    }

    return true
  }



  return (
    <div className="login">
      <div className="left">
        <div className="w-75">
          <h2>
            Register with <span> DSPLATFORM</span>
          </h2>

          <div className="input_box mb-4">
            <label className="mb-3">First Name</label>
            <div class="input-group">
              <span class="input-group-text">
                <img src={user} className="img-fluid" alt="img" />
              </span>
              <input
                type="text"
                class="form-control py-3"
                placeholder="Enter your first name"
                name="Username"
                onChange={handleChange}

              />
            </div>{" "}
            {validateError.name && (
              <span className="text-danger">{validateError.name}</span>
            )}
          </div>
          <div className="input_box mb-4">
            <label className="mb-3">Last Name</label>
            <div class="input-group">
              <span class="input-group-text">
                <img src={user} className="img-fluid" alt="img" />
              </span>
              <input
                type="text"
                class="form-control py-3"
                placeholder="Enter your last name"
                name="lastname"
                onChange={handleChange}

              />
            </div>{" "}
            {validateError.lastname && (
              <span className="text-danger">{validateError.lastname}</span>
            )}
          </div>
          <div className="input_box mb-4">
            <label className="mb-3">Email Address</label>
            <div class="input-group">
              <span class="input-group-text">
                <img src={email} className="img-fluid" alt="img" />
              </span>
              <input
                type="text"
                class="form-control py-3"
                placeholder="Enter your email address"
                name="emailId"
                onChange={handleChange}
              />
            </div>
            {validateError.email && (
              <span className="text-danger">{validateError.email}</span>
            )}
          </div>

          <div className="input_box mb-4">
            <label className="mb-3">Mobile Number</label>
            <div class="input-group">
              <span class="input-group-text">
                <img src={mobile} className="img-fluid" alt="img" />
              </span>
              <input
                // type={phone ? "number" : 'text'}
                class="form-control py-3"
                placeholder="Enter your mobile number"
                name="phone"
                onChange={handleChange}
                onInput={validPositive}

              />
            </div>

            {validateError.phoneNo && (
              <span className="text-danger">{validateError.phoneNo}</span>
            )}
          </div>

          <div className="input_box mb-4">
            <label className="mb-3">Password</label>
            <div class="input-group">
              <span class="input-group-text">
                <img src={lock} className="img-fluid" alt="img" />
              </span>
              <input
                type={showPassword ? "text" : "password"}
                class="form-control py-3"
                name="password"
                placeholder="Enter your Password"
                onChange={handleChange}
              />
              <span
                class="input-group-text cursor-pointer"
                onClick={handlePassword}
              >
                <img
                  src={showPassword ? eye : hide}
                  className="img-fluid"
                  alt="img"
                />
              </span>
            </div>
            {validateError.password && (
              <span className="text-danger">{validateError.password}</span>
            )}
          </div>
          {password.length !== 0 ? (
            <>
              <div className="progress-bg">
                <div
                  className="progress"
                  style={{
                    marginTop: "-24px",
                    height: "6px",
                    width: progress,
                    backgroundColor: getActiveColor(message)
                  }}
                ></div>
              </div>
              <p className="message" style={{ color: getActiveColor(message) }}>
                Your Password is {message}
              </p>
            </>
          ) : null}

          <div className="input_box mb-4">
            <label className="mb-3">Confirm Password</label>
            <div class="input-group">
              <span class="input-group-text">
                <img src={lock} className="img-fluid" alt="img" />
              </span>
              <input
                type={reshowPassword ? "text" : "password"}
                class="form-control py-3"
                name="confirmpassword"
                placeholder="Enter your re-enter password"
                onChange={handleChange}
              />
              <span
                class="input-group-text cursor-pointer"
                onClick={rePassword}
              >
                <img
                  src={reshowPassword ? eye : hide}
                  className="img-fluid"
                  alt="img"
                />
              </span>
            </div>{" "}
            {validateError.confirmpassword && (
              <span className="text-danger">
                {validateError.confirmpassword}
              </span>
            )}
          </div>

          <button
            className="primary_btn mb-4 w-100"
            onClick={handleSubmit}
          disabled={disable}
          >
            {/* {isValidC ? "Register" : "Camp ID Not Valid"} */}
            Register
          </button>
          <div className="signup text-center">
            Already have an account? <a href="/login"> Login here</a>
          </div>
        </div>
      </div>
      <div className="right d-none d-lg-flex">
        <div className="text-center">
          <img src={register_logo} className="img-fluid mb-4" alt="img" />
          <p className="para mb-5">
            Log in now to DSPLATFORM to better manage your dropshipping account
            and increase your profits!
          </p>
          <img src={login} className="img-fluid" alt="img" />
        </div>
      </div>
    </div>
  );
}
