import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
// img
import w1 from "../assets/images/w1.svg";
import Navbarinner from "../components/Navbarinner.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function About() {


  return (
    <div>
      <Navbar />
      <div className="common_page" >
        <div className="container">
        <h1 className="h2tag" ><strong>About Us</strong></h1> 

          <ul>
            <li><strong>2022</strong>DSPLATFORM was founded</li>
            <li><strong>45K+</strong>monthly active users</li>
            <li><strong>97+</strong>countries users are from</li>
            <li><strong>50+</strong>employees</li>
          </ul>
          
          <h2><strong><em><strong><em>Your all-in-one platform for product research &amp; fulfillment</em></strong></em></strong></h2>
          <p>Good product research is key to success, but the reality of sourcing, dealing with agents, international shipping and customs is more complex than most expect. DSPLATFORM&rsquo;s vision is to remove this complexity. What you see is what you can get right here in your dashboard with just a few clicks. No external automation services, no dealing with suppliers.</p>
          
          <h2><strong><em><strong><em>Experience knowing rather than guessing</em></strong></em></strong></h2>
          <p>DSPLATFORM&rsquo;s research team works with standalone-shop, Amazon, and eBay sellers of all sizes that test assumptions and help DSPLATFORM&rsquo;s analysts validate their data. Thanks to a network of partners, DSPLATFORM&rsquo;s prediction methodology is not just paper-based, but largely field-tested in practice. It&rsquo;s accurate research, not made-up numbers made by pretty much all so-called &ldquo;sales trackers&rdquo;.</p>
          
          <h3><strong>&middot;&nbsp;</strong><strong><strong>Transparency</strong></strong></h3>
          <p>We know how frustrating it can be to be overwhelmed with tons of anonymous websites and fictional numbers. We fix that. DSPLATFORM is a company in the heart of London whose founders stand behind their research methodology and services with their names.</p>
          
          <h3><strong>&middot;&nbsp;</strong><strong><strong>Professionalism</strong></strong></h3>
          <p>We understand your basic needs to be able to operate as a business: proper invoices, necessary product certifications, competent advice. The support team consists of native English speakers who can handle even the toughest questions and bureaucracy.</p>
          
          <h3><strong>&middot;&nbsp;</strong><strong><strong>Entrepreneurial Spirit</strong></strong></h3>
          <p>We want you to be successful, especially if you are a beginner. That&rsquo;s why we made DSPLATFORM&rsquo;s dashboard as easy as possible to use, very lean, with many features for scalability and growth that we wish we would&rsquo;ve had when building our first stores.</p>
          
          <h3><strong>&middot;&nbsp;</strong><strong><strong>Optimization</strong></strong></h3>
          <p>DSPLATFORM&rsquo;s dashboard is just the beginning. We improve, we add features, we ask for feedback, and we implement feedback. We believe that when quality improves, growth comes naturally. Please give us as much feedback as possible. Every bit counts!</p>
          
          <h3><strong>&middot;&nbsp;</strong><strong><strong>E-Commerce for Everyone</strong></strong></h3>
          <p>We want fewer big players, like Amazon and Costco, and more entrepreneurs. DSPLATFORM empowers independent sellers, helping them form a diverse market for everyone. Let&rsquo;s learn from each other, grow together, and help others succeed.</p>
          
          <h3><strong>&middot;&nbsp;</strong><strong><strong>Love</strong></strong></h3>
          <p>DSPLATFORM&rsquo;s team members create features they love. No one implements anything that they wouldn&rsquo;t use themselves. We rally people that have a deep passion for e-commerce. This passion is shared with every single subscriber through the dashboard.</p>
          
          
          <ul>
            <li><a href="#"><u>Affiliate Program</u></a></li>
          </ul>
          
          
        </div>
      </div>
      <Footer />
    </div>
  );
}
